.signup {
  background: rgba(0,0,0,.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.signup--content {
  background: #FBFBFB;
  margin: auto;
  padding: 40px;
}

@media #{$desktop} {
  .signup--content {
    max-width: 540px;
    width: 100%;
    border-radius: 24px;
  }

  .signup {
    display: flex;
    align-items: center;
    min-height: 100vh;
    z-index: 105; //Above header
  }
}

@media #{$mobile} {
  .signup {
    z-index: 95; //Bellow header
  }

  .signup--content {
    padding-top: $height_header_mob;
    min-height: 100vh;
    padding-left: 15px;
    padding-right: 15px;
  }

  .signup--title {
    border-bottom: 1px solid #A64AC9;
  }
}

//Fast Hacking
@media #{$mobile} {
  .signup--title ~ div {
    font-size: 12px;
  }
}