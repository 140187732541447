.drawer {
  overflow: auto;
  z-index: 100;
  position: fixed;
  background: $color-highlight;
  color: #fff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 0 20px 0;
  transition: transform .5s cubic-bezier(0.36, 1.08, 0.5, 1) 0.05s;
  will-change: transform;
  transform: translate(100%, 0);
  pointer-events: none;

  &.isVisible {
    transform: translate(0, 0);
    pointer-events: initial;
  }

  .header--navlink {
    display: block;
    margin: 24px 0 !important;
    font-size: 20px;
    padding: 0 20px;
    text-transform: none;

    &.l-button {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.drawer--header {
  height: $height_header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  a {
    display: block;
    line-height: 0;
  }
}

@media #{$mobile} {
  .drawer--header {
    height: $height_header_mob;
  }
}

.drawer--separator {
  margin: 24px 20px;
  border-color: #fff;
  max-width: 310px;
}