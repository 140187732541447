:root {
  font-size: $sizes-font-root;
  line-height: 1.5;
}

*, *:after, *:before {
  box-sizing: border-box;
}

html, body {
  background: $color-void;
  font-size: 1rem;
  font-family: $font-family;
  margin: 0;
  color: $color-font;
}

input, textarea, select, button {
  color: inherit;
  font-family: inherit;
}

hr {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #F2F2F2;
  margin: 16px 0;
}

a, a:link, a:visited {
  color: inherit;
  text-decoration: none;
}

img, video {
  display: block;
}

pre {
  font-family: inherit;
  white-space: pre-wrap;
  margin: 0;
}