.rdrCalendarWrapper {
  width: 100%;
}

.l-block-center {
  margin-left: auto;
  margin-right: auto;
}

.l-inline-center {
  text-align: center;
}

.l-inline-right {
  text-align: right;
}

.l-inline-left {
  text-align: left;
}

.l-pad-15 {
  padding: 15px;
}

.l-pad-30 {
  padding: 30px;
}

.l-pad-45 {
  padding: 45px;
}

.l-content {
  max-width: 1250px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.l-content-small {
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.l-flex-center {
  display: flex;
  align-items: center;
  > * {
    margin: auto;
  }
}

.l-flex-align-center {
  display: flex;
  align-items: center;
}

.l-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l-flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.l-flex-grow {
  flex-grow: 1;
}

%button {
  padding: 14px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.l-button {
  @extend %button;
  @extend .t-button-highlight;
}

.l-button-mute {
  @extend %button;
}

.l-button-outline {
  @extend %button;
  @extend .t-button-highlight-light-outline;
}

.l-clickable {
  cursor: pointer;
}

.l-full-width {
  display: block;
  width: 100%;
}

.l-text-bold {
  font-weight: 700;
}

.l-text-discreet {
  color: #a8a8a8;
}

.l-text-discreeter {
  color: #eee;
}

.l-text-weight-normal {
  font-weight: normal !important;
}

.l-text-default-family {
  font-family: $font-family;
}

.l-text-alternate-family {
  font-family: "SF Pro Text";
}

.l-text-highlight-alternate {
  color: $color-secondary;
}

.l-text-discreet .l-underline {
  border-bottom: 2px solid #999;
}

.l-underline {
  border-bottom: 2px solid black;
}

.l-no-wrap {
  white-space: nowrap;
}

.l-view {
  position: relative;
  padding-bottom: $height_footer;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

@media #{$mobile} {
  .l-view {
    padding: 0 0 $height_footer 0;
  }

  .l-mob-hide {
    display: none !important;
  }

  .l-mob-small-vpadding {
    padding: 25px 0 !important;
  }
}

@media #{$desktop} {
  .l-desk-hide {
    display: none !important;
  }

  .jobCreate .paymentSummary {
    position: sticky;
    top: 120px;
  }
}

.jobCreate .fieldLabel {
  font-weight: bold !important;
}
