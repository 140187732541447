.hero {

}

.hero--section {
  position: relative;
}

.hero--section-first {
  position: relative;
  overflow: hidden;
  background: url("/lines.svg");
  background-color: #F5F7FA;
  background-size: cover;
}

.hero--section-first--paragraph1 {
  position: absolute;
  top: 25vh;
}

.hero--section-first--paragraph2 {
  position: absolute;
  top: 60vh;
}

.hero--section-first--girl {
  position: absolute;
  top: 20vh;
  right: 50px;
}

.hero--title {
  font-family: "Bebas Neue";
  line-height: 80px;
  font-weight: 400;
}

.hero--subtitle {
  font-family: "Bebas Neue";
  position: relative;
  display: inline-block;
}

.hero--subtitle--dash {
  display: inline-block;
  position: absolute;
  bottom: 14px;
  left: -4px;
  content: "";
  height: 16px;
  width: 85px;
  background: #FCCC0A;
}

.hero--video {
  height: 100vh;
  object-fit: cover;
  width: 100%;
}

.hero--section--content {
  @extend .l-content;
  min-height: 100vh;
  padding-bottom: 25px;
  position: relative;
}

@media #{$desktop} {
  .hero--section-first--girl img {
    height: 700px;
  }
}

@media #{$mobile} {
  .hero--section--register {
    .l-desk-4-12, .hero--title, .hero--section--force-center {
      text-align: center !important;
    }
  }

  .hero--section-first--paragraph2 {
    position: initial;
  }

  .hero--section-first {
    text-align: center;
  }

  .hero--section-first--paragraph1 {
    position: initial;

    h1 {
      margin: 0;
    }
  }

  .hero--section-first--girl {
    position: initial;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .hero--section--margin--small {
    margin-top: 15px !important;
  }
}