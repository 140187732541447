$margin-right: 3%;

.l-row {
  padding: 0;
  font-size: 0;
}

.l-row > * {
  font-size: 1rem;
  vertical-align: top;
  display: inline-block;
  margin-right: $margin-right;
}

.l-row > *:last-child {
  margin-right: 0;
}

.l-desk-12-12 {
  width: 100%;
}

.l-desk-11-12 {
  width: 100% / 12 * 11 - ($margin-right / 12 * 1);
}

.l-desk-10-12 {
  width: 100% / 12 * 10 - ($margin-right / 12 * 2);
}

.l-desk-9-12 {
  width: 100% / 12 * 9 - ($margin-right / 12 * 3);
}

.l-desk-8-12 {
  width: 100% / 12 * 8 - ($margin-right / 12 * 4);
}

.l-desk-7-12 {
  width: 100% / 12 * 7 - ($margin-right / 12 * 5);
}

.l-desk-6-12 {
  width: 100% / 12 * 6 - ($margin-right / 12 * 6);
}

.l-desk-5-12 {
  width: 100% / 12 * 5 - ($margin-right / 12 * 7);
}

.l-desk-4-12 {
  width: 100% / 12 * 4 - ($margin-right / 12 * 8);
}

.l-desk-3-12 {
  width: 100% / 12 * 3 - ($margin-right / 12 * 9);
}

.l-desk-2-12 {
  width: 100% / 12 * 2 - ($margin-right / 12 * 10);
}

.l-desk-1-12 {
  width: 100% / 12 * 1 - ($margin-right / 12 * 11);
}

@media #{$mobile} {

  .l-row > * {
    font-size: 1rem;
    vertical-align: top;
    display: block;
    margin-bottom: 20px;
  }

  .l-desk-12-12,
  .l-desk-11-12,
  .l-desk-10-12,
  .l-desk-9-12,
  .l-desk-8-12,
  .l-desk-7-12,
  .l-desk-6-12,
  .l-desk-5-12,
  .l-desk-4-12,
  .l-desk-3-12,
  .l-desk-2-12,
  .l-desk-1-12 {
    width: 100%;
  }

  .l-mob-12-12 {
    width: 100%;
    display: inline-block;
  }

  .l-mob-11-12 {
    width: 100% / 12 * 11 - ($margin-right / 12 * 1);
    display: inline-block;
  }

  .l-mob-10-12 {
    width: 100% / 12 * 10 - ($margin-right / 12 * 2);
    display: inline-block;
  }

  .l-mob-9-12 {
    width: 100% / 12 * 9 - ($margin-right / 12 * 3);
    display: inline-block;
  }

  .l-mob-8-12 {
    width: 100% / 12 * 8 - ($margin-right / 12 * 4);
    display: inline-block;
  }

  .l-mob-7-12 {
    width: 100% / 12 * 7 - ($margin-right / 12 * 5);
    display: inline-block;
  }

  .l-mob-6-12 {
    width: 100% / 12 * 6 - ($margin-right / 12 * 6);
    display: inline-block;
  }

  .l-mob-5-12 {
    width: 100% / 12 * 5 - ($margin-right / 12 * 7);
    display: inline-block;
  }

  .l-mob-4-12 {
    width: 100% / 12 * 4 - ($margin-right / 12 * 8);
    display: inline-block;
  }

  .l-mob-3-12 {
    width: 100% / 12 * 3 - ($margin-right / 12 * 9);
    display: inline-block;
  }

  .l-mob-2-12 {
    width: 100% / 12 * 2 - ($margin-right / 12 * 10);
    display: inline-block;
  }

  .l-mob-1-12 {
    width: 100% / 12 * 1 - ($margin-right / 12 * 11);
    display: inline-block;
  }
}