.wkItem {
  white-space: normal;
}

.wkItem--title {
  @extend .l-text-alternate-family;
}

@media #{$desktop} {
  .wkItem {
    width: 400px;
  }

  .dashboard--grid .wkItem > * {
    height: 475px;
  }

  .admin.dashboard--grid .wkItem > * {
    height: 515px;
  }

  .wkItem--content {
    display: flex;
    flex-direction: column;
  }

  .wkDetails--card {
    .wkItem {
      max-width: 400px;
      width: 100%;
    }
  }

  .carousel--content .wkItem {
    > * {
      display: inline-flex !important;
      flex-direction: column !important;
      height: 550px;
    }
  }

}

@media #{$desktop} {
  .wkItem--title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media #{$mobile} {
  .wkItem {
    text-align: center;
    width: 100%;

    > * {
      text-align: left;
    }
  }
}

@media #{$mobile-smaller} {
  .wkItem--coverImg {
    height: 165px !important;
  }
}