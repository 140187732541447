$table-side-padding: 25px;
.table {
  width: 100%;
  border-collapse: collapse;
}

.table-cards  {
  text-align: left;
  table-layout: fixed;
  color: #666;

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: top;
  }

  th {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.table-cards-transpose {
    @extend .table-cards;

    td:nth-child(1) {
      font-weight: bold;
    }
}

.table--header {
  padding: $table-side-padding;
  border-bottom: 1px solid #e9e9e9;
  text-align: left;
}

.table--cell {
  font-size: 13px;
  padding: 15px $table-side-padding;
}

.table tbody tr:nth-child(odd) {
  background: #F9F9F9;
}