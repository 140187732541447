// .duration-picker-ampm {
//   li[data-value="am"] {
//     visibility: hidden;
//   }

//   li[data-value="pm"] {
//     visibility: hidden;
//   }
// }

// .rc-time-picker-panel-select {
//   display: none !important;
// }

.rmdp-day-picker {
  display: contents !important;
}
