//Date Picker
.rmdp-wrapper {
  box-shadow: none !important;
}

.rmdp-day {
  margin: 5px 0;
  width: 35px;
  height: 35px;
  left: initial;
  top: initial;
  right: initial;
  bottom: initial;
}

.rmdp-day.rmdp-today span {
  background: #e5e5e5;
}

.rmdp-day span {
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  font-size: 14px;
  flex-direction: initial;
}

.rmdp-day.rmdp-selected span {
  background: #325e8c !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background: #7d9baf !important;
}

.rmdp-wrapper > div > div {
  width: 400px !important;
  height: 230px;
}

.rmdp-week {
  justify-content: space-between !important;
  line-height: 18px;
}

.rmdp-week-day {
  color: #333 !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
}

.rmdp-arrow {
  padding: 7px !important;
  border-color: #333 !important;
}

.rmdp-header {
  margin-bottom: 20px;
  font-weight: 500;
}

//Material UI

[class*="MuiInputAdornment-positionStart"] {
  position: absolute;
  top: 28px;
}

[class*="MuiInputAdornment-positionStart"] {
  + input,
  + textarea {
    padding-left: 30px;
  }
}

.blue-menu {
  position: relative;

  .blue-menu-item {
    padding: 10px 25px;
    font-weight: bold;
    &:hover {
      color: #ff8f8f;
    }
  }

  .MuiPopover-paper {
    overflow: visible !important;
  }

  .MuiPaper-root {
    background: #28588c;
    color: #fff;
    text-align: right;
    border-radius: 10px;
    padding: 10px;
  }

  .drawer-arrow {
    display: inline-block;
    position: absolute;
    top: -10px;
    font-size: 0;
    padding: 0;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid #28588c;
  }
}

.drawer-arrow {
  position: relative;
}

.MuiDialogTitle-root {
  padding-bottom: 8px !important;
}

.MuiSvgIcon-root {
  font-size: 2.3rem !important;
  stroke-width: 1px !important;
  stroke: #fff !important;
}

//.MuiDialog-paperWidthXs {
//  max-width: 350px !important;
//}

.MuiDialogContent-root {
  padding-top: 8px !important;
  padding-bottom: 16px !important;
}

.MuiRating-sizeLarge {
  font-size: 3.5rem !important;
}

.MuiLinearProgress-bar {
  background-color: #ff4242 !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #fcd6d6 !important;
}

.MuiOutlinedInput-root,
.MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0 !important;
}

.MuiOutlinedInput-root.Mui-error fieldset {
  border-color: red !important;
}

.MuiPopover-paper {
  font-weight: 500 !important;
  margin-top: 45px;
  position: relative;

  ul > * {
    display: block;
    padding: 10px 20px;
  }
}

.StripeElement {
  @extend .l-flex-align-center;
  width: 100% !important;
  height: 54px !important;
  padding: 0 14px !important;

  > * {
    width: 100% !important;
  }
}

// CHECKBOX
.MuiCheckbox-root .MuiIconButton-label {
  border: 1px solid #959595;
  border-radius: 5px;
  position: relative;
}
.MuiCheckbox-root.Mui-checked .MuiIconButton-label::after {
  position: absolute;
  content: "";
  width: 0.95rem;
  height: 0.95rem;
  border-radius: 3px;
  background: #666;
}
.MuiCheckbox-root svg {
  opacity: 0;
  width: 1.75rem !important;
  height: 1.75rem !important;
}
.MuiCheckbox-root {
  padding: 0 10px !important;
}

.rmdp-arrow-container:hover {
  background-color: inherit !important;
  box-shadow: none !important;
}

.StripeElement {
  display: flex !important;
}

.force-placeholder-stripe ::-webkit-input-placeholder {
  color: initial !important;
}

.rc-time-picker {
  width: 90px !important;
}
