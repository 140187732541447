.t-color-success {
  color: $color-success
}

.t-color-danger {
  color: $color-danger
}

.t-color-highlight {
  color: $color-highlight
}

.t-surface {
  background: $color-surface;
  color: $color-font;
  border-radius: 10px;
}

.t-surface-card {
  background: #FFFFFF;
  box-shadow: 0px 3px 10px rgba(43, 43, 43, 0.1);
  border-radius: 10px;
}

.t-surface-mute {
  background-color: #F0F1F1 !important;
  color: #B3B7BB !important;
}

.t-surface-mute-darker {
  background-color: #B3B7BB !important;
  color: #fff !important;
}

.t-surface-dark {
  background: $color-surface-dark;
  color: $color-surface-dark-oppose;
}

.t-surface-danger {
  background: $color-danger;
  color: $color-font-oppose;
}

.t-surface-highlight-alternate {
  background-color: #FCCC0A;
  color: black;
}

.t-surface-highlight-alternate2 {
  background-color: #FCCC0A;
  color: #fff;
}

.t-button-highlight {
  background: $color-highlight;
  color: #fff;
  border: none;
  &:visited,&:link {
    color: #fff;
  }
}

.t-button-highlight-mute {
  background: #EEE0F3;
  color: #A64AC9;
  border: none;
}

.t-button-highlight2-mute {
  background: #D9F4F7;
  color: #09C4E0;
  border: none;
}

.t-button-highlight-light-outline {
  background: transparent;
  color: $color-highlight-light !important;
  border: 1px solid $color-highlight-light;
}

.t-highlight-light {
  color: $color-highlight-light !important;
}