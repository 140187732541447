$mobile-smaller: "only screen and (max-width: 400px)";
$mobile: "only screen and (max-width: 1328px)";
$desktop: "only screen and (min-width: 1329px)";

$font-family: "Circular Std", sans-serif;
$sizes-font-root: 15px;

$color-font: #444;
$color-void: transperent;
$color-surface: #fff;
$color-surface-dark: #262a2d;

$color-highlight: #325e8c;
$color-highlight-light: #09c4e0;

$color-muted: #959595;
$color-success: #0b9600;
$color-danger: #db0000;

$color-font-oppose: #fff;
$color-surface-oppose: #071822;
$color-surface-dark-oppose: #fff;

$color-icon: #c4c4c4;
$color-primary: #810bbf;
$color-secondary: #00bbb1;

$height_header: 80px;
$height_header_mob: 72px;
$height_footer: 80px;
