.carousel--content {
  white-space: nowrap;
  overflow: hidden;

  > * {
    display: inline-block;
    transition: transform .3s ease-in-out .05s;
  }
}


@media #{$mobile} {
  .carousel--content {
    > * {
      width: 100%;
    }
  }

  .carousel--proofs > * {
    text-align: center;
  }
}

.carousel--proofsLabel {
  @extend .l-text-alternate-family;
  font-size: 24px;
  position: absolute;
  display: flex;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), black);
  padding: 0 0 15px 15px;
  color: #ffffff;
}

.carousel--proofsImg {
  height: 420px !important;
  width: 200px !important;
}

@media #{$mobile} {
  .carousel--proofsImg {
    height: (420px / 1.4) !important;
    width: (200px / 1.4) !important;
  }
}