$wk-footer-height: 88px;
$wk-navigation-width: 390px;

.wkDetails--content {
  display: flex;
  padding-top: 44px;
  position: relative;
}

.wkDetails--description {
  flex-grow: 1;
  flex-shrink: 1;
  width: 580px;
}

.wkDetails--paragraph {
  font-weight: 400;
}

.wkDetails--card {
  flex-shrink: 0;
  flex-grow: 1;
  margin-left: 50px;
}

.wkDetails--footer {
  @extend .t-surface;
  @extend .l-flex-around;
  position: fixed;
  width: 100%;
  padding: 10px 16px;
  bottom: 0;
  left: 0;
  height: $wk-footer-height;
  box-shadow: 0px -8px 40px rgba(217, 159, 204, 0.15);
}

.wkDetails--split {
  display: flex;
  height: 100%;
}

@media #{$desktop} {
  .wkDetails--navigation {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: $wk-navigation-width;
    min-width: $wk-navigation-width / 1.25;
  }
}

@media #{$mobile} {
  .wkDetails--navigation {
    flex-grow: 0;
    flex-shrink: 0;
    width: $wk-navigation-width;
  }
}

.wkDetails--navigation {
  position: relative;
  padding: 24px;
  height: 100%;
  background: #F1F3F6;
  color: #777A7F;
  overflow: auto;
}

.wkDetails--navigationItem {
  @extend .l-flex-between;
  border-bottom: 1px solid #DADCE0;
  border-radius: 5px;
  padding: 16px;
  color: #3F4347;
  &:last-child {
    border-bottom: none;
  }
}

.wkDetails--sectionNavigation {
  position: sticky;
  bottom: 0;
  width: 100%;
  right: 0;
  height: 80px;
  background: #fff;
  box-shadow: 0px -8px 40px rgba(222, 77, 151, 0.05);
}

.wkDetails--sectionNavigationControl {
  font-weight: 500;
  color: #00D0E2;
  &.isDisabled {
    color: #B3B7BB;
  }
}

.wkDetails--attachment {
  @extend .l-flex-align-center;
  height: 65px;
  border-bottom: 1px solid #E3E8EE;
  font-size: 18px;
  color: $color-highlight;
  &:first-child {
    border-top:  1px solid #E3E8EE;
  }
}
@media #{$mobile} {
  .wkDetails--paragraph {
    font-weight: 400;
  }

  .wkDetails--navigation {
    width: 100vw;
  }

  .wkDetails--content {
    padding-bottom: 95px;
  }

  .wkDetails--sectionNavigation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .wkDetails--footerProgress {
    height: ($wk-footer-height * .75) !important;
    width: ($wk-footer-height * .75) !important;
  }
}